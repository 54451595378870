import One from '../images/artwork/2016/1.jpg'
import Two from '../images/artwork/2016/2.jpg'
import Three from '../images/artwork/2016/3.jpg'
import Four from '../images/artwork/2016/6.jpg'
import Five from '../images/artwork/2016/7impel.jpg'
import Six from '../images/artwork/2016/8Bugeon.jpg'
import Seven from '../images/artwork/2016/9stream.jpg'
import Eight from '../images/artwork/2016/10.jpg'
import Nine from '../images/artwork/2016/11Amplify.jpg'
import Ten from '../images/artwork/2016/IMG_5861.jpg'
import Eleven from '../images/artwork/2016/3.jpg'
import Twelve from '../images/artwork/2016/IMG_5882.jpg'
import Thirteen from '../images/artwork/2016/2016-5933.jpg'
import Fourteen from '../images/artwork/2016/Paragraph_01.jpg'

export const photos = [
    {
        src: Twelve,
        width: 3.5,
        height: 4,
        // title: "Desiree's Guardian Angel, 2019"
      },
      {
        src: Thirteen,
        width: 4.5,
        height: 3,
        // title: "Desiree's Guardian Angel, 2019"
      },
      {
        src: Fourteen,
        width: 4,
        height: 3,
        // title: "Desiree's Guardian Angel, 2019"
      },
    {
        src: Ten,
        width: 3,
        height: 4,
        // title: "Desiree's Guardian Angel, 2019"
      },
      {
        src: One,
        width: 1,
        height: 2,
        // title: "Slake, 2017",
      },
      {
        src: Two,
        width: 3,
        height: 4,
        // title: "I'm Working on my Posture, 2017"
      },
      {
        src: Three,
        width: 4,
        height: 3,
        // title: "Wing, 2017"
      },
      {
        src: Four,
        width: 3,
        height: 4,
        // title: "Yellow Heap, 2018"
      },

    
      {
        src: Seven,
        width: 4,
        height: 3.5,
        // title: "Horse Race, 2018"
      },
      {
        src: Eight,
        width: 3.5,
        height: 4,
        // title: "Desiree's Guardian Angel, 2019"
      },
      {
        src: Nine,
        width: 3,
        height: 4,
        // title: "Desiree's Guardian Angel, 2019"
      },
  ];